import axios from "axios";
import { useRequestProcessor } from "./useRequestProcessor";
import { FEATURE_FLAGS_QUERY_KEY } from "common/query-keys";

export const useGetFeatureFlags = () => {
    const { useQuery } = useRequestProcessor();

    return useQuery([FEATURE_FLAGS_QUERY_KEY], () =>
        axios.get("admin/user/flags").then((res) => res.data.result),
    );
};

export const useGetBrandFeatureFlagStatuses = (brandUserId) => {
    const { useQuery } = useRequestProcessor();

    return useQuery(
        [FEATURE_FLAGS_QUERY_KEY, brandUserId],
        () =>
            axios
                .get(`admin/user/flags/statuses`, { params: { brandUserId } })
                .then((res) => res.data.result),
        {
            enabled: !!brandUserId,
        },
    );
};
