import axios from "axios";

export const getBrands = async () => {
    return axios
        .get("admin/user/search")
        .then((response) => {
            return response.data.result;
        })
        .catch((error) => {
            console.error("Failed to get brands");
            console.error(error);
        });
};

export const getBrand = async (id) => {
    return axios
        .get(`admin/user/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error("Failed to get brand info");
            console.error(error);
        });
};

export const createNewUser = async (brandData) => {
    return axios
        .post(`admin/user/create-user-internal`, brandData)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error("Failed to create new brand");
            console.error(error);
        });
};

export const updateBrand = async (brandData) => {
    return axios
        .post("admin/user/brand", brandData)
        .then((response) => {
            return response.data.result;
        })
        .catch((error) => {
            console.error("Failed to update brand");
            console.error(error);
        });
};
