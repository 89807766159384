import { Col, Form, Input, message, Modal, Row, Select } from "antd";
import { getLookupsByType } from "common/api";
import { requiredFormRule } from "common/constants";
import DatePicker from "common/DatePicker";
import {
    TASK_DATA_MINING,
    TASK_IG_TAGGED_MEDIA_SYNC,
    TASK_PROCESS_ACCOUNT_LIST_TO_LIMIT,
    TASK_STATUS_READY,
    TASK_STATUS_RUNNING,
    TASK_STATUS_NEW,
    TASK_STATUS_ERROR,
    TASK_STATUS_COMPLETE,
    TASK_STATUS_CANCELED,
    TASK_STATUS_WORKFLOW_QUEUE,
    LOOKUP_TASK,
    LOOKUP_TASK_STATUS,
    TASK_IMPORT_CUSTOMER,
} from "common/types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { updateUserTasks } from "./tasks.api";

const TASK_TYPES = [
    TASK_PROCESS_ACCOUNT_LIST_TO_LIMIT,
    TASK_DATA_MINING,
    TASK_IG_TAGGED_MEDIA_SYNC,
    TASK_IMPORT_CUSTOMER,
];
const TASK_STATUS_TYPES_ENABLED = [TASK_STATUS_READY, TASK_STATUS_COMPLETE];
const TASK_STATUS_TYPES = [
    TASK_STATUS_READY,
    TASK_STATUS_RUNNING,
    TASK_STATUS_NEW,
    TASK_STATUS_ERROR,
    TASK_STATUS_COMPLETE,
    TASK_STATUS_CANCELED,
    TASK_STATUS_WORKFLOW_QUEUE,
];

export const TaskModal = ({ isOpen, task = null, onCancel, onSuccess }) => {
    const { userId } = useParams();

    const [taskTypes, setTaskTypes] = useState(null);
    const [statusTypes, setStatusTypes] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        getLookupsByType(LOOKUP_TASK).then((t) => setTaskTypes(t));
        getLookupsByType(LOOKUP_TASK_STATUS).then((t) => setStatusTypes(t));
    }, []);

    useEffect(() => {
        if (task) {
            form?.setFieldsValue({
                udf1: task.udf1,
                udf2: task.udf2,
                type: task.type?.id,
                nextRunDate: task.nextRunDate
                    ? new Date(task.nextRunDate)
                    : null,
                endDate: task.endDate ? new Date(task.endDate) : null,
                status: task.status?.id,
            });
        } else {
            form?.setFieldsValue({
                status: TASK_STATUS_NEW,
                udf1: null,
                udf2: null,
            });
        }
    }, [task]);

    const onOk = (values) => {
        const { udf1, udf2, type, nextRunDate, endDate, status } = values;

        setIsLoading(true);

        updateUserTasks(userId, {
            ...task,
            type: { id: type },
            status: { id: status },
            nextRunDate,
            endDate,
            udf1,
            udf2,
        })
            .then((data) => {
                if (data.success) {
                    onCancel();
                    onSuccess();
                } else {
                    message.error(
                        data.message ||
                            `Failed to ${task?.id ? "update" : "create"} task`,
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onTypeChange = (_, option) => {
        form?.setFieldsValue({ udf1: option.udf1 });
    };

    let taskOptions = [];
    if (!!taskTypes?.length) {
        taskOptions = TASK_TYPES.map((t) => {
            const type = taskTypes?.find((taskType) => taskType.id === t);
            return { value: type.id, label: type.descr, udf1: type.value };
        });
    }

    //TODO:
    //only two types can be selected to save Task. Other types needed to display current Task status.
    //If disabled options is bad UX or missleading for users this can be rewrited to custom <Option> component with display:none style for non-selectable statuses
    let statusOptions = [];
    if (!!statusTypes?.length) {
        statusOptions = TASK_STATUS_TYPES.map((t) => {
            const type = statusTypes?.find((taskType) => taskType.id === t);
            const enabled = TASK_STATUS_TYPES_ENABLED.includes(t);
            return {
                value: type.id,
                label: type.descr,
                disabled: task?.id ? !enabled : false,
            };
        });
    }

    return (
        <Modal
            open={isOpen}
            title={task ? `Edit Task : ${task.id}` : "Add New Task"}
            okText="Save"
            onCancel={onCancel}
            width={700}
            destroyOnClose
            okButtonProps={{
                loading: isLoading,
                form: "task-form",
                key: "submit",
                htmlType: "submit",
            }}>
            <Form
                id="task-form"
                form={form}
                layout="vertical"
                onFinish={onOk}
                validateTrigger={false}
                preserve={false}>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item
                            label="Task Type"
                            name="type"
                            rules={[{ ...requiredFormRule, type: "number" }]}>
                            <Select
                                onChange={onTypeChange}
                                disabled={!!task?.id}
                                options={taskOptions}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            initialValue={
                                task?.id ? task?.status?.id : TASK_STATUS_NEW
                            }
                            rules={[
                                {
                                    type: "number",
                                    validator: async (_, val) => {
                                        if (
                                            task?.id &&
                                            !TASK_STATUS_TYPES_ENABLED.includes(
                                                val,
                                            )
                                        ) {
                                            throw new Error();
                                        }
                                    },
                                    message:
                                        "You can only select the Ready or Complete status when editing a task.",
                                },
                            ]}
                            name="status">
                            <Select
                                options={statusOptions}
                                disabled={!task?.id}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Next Run Date"
                            name="nextRunDate"
                            rules={[{ ...requiredFormRule, type: "date" }]}
                            initialValue={
                                task?.id ? task?.nextRunDate : new Date()
                            }>
                            <DatePicker />
                        </Form.Item>
                        {!!task?.id && (
                            <Form.Item
                                label="End Date"
                                name="endDate"
                                initialValue={task?.endDate}>
                                <DatePicker />
                            </Form.Item>
                        )}
                        {!task?.id && (
                            <Form.Item
                                label="Udf1"
                                name="udf1"
                                tooltip="This value is auto set based on the task type selection">
                                <Input disabled />
                            </Form.Item>
                        )}
                        {!task?.id && (
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) =>
                                    prevValues.type !== currentValues.type
                                }>
                                {({ getFieldValue }) =>
                                    getFieldValue("type") ===
                                    TASK_PROCESS_ACCOUNT_LIST_TO_LIMIT ? (
                                        <Form.Item
                                            label="List Ids"
                                            name="udf2"
                                            tooltip={
                                                "Comma separated value of List IDs or a single ID. (ie: 2,10,33)"
                                            }>
                                            <Input />
                                        </Form.Item>
                                    ) : null
                                }
                            </Form.Item>
                        )}
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
