import { Input, Table } from "antd";
import { brandsColumns } from "./brand_columns";
import { getBrands } from "./brands.api";
import { useEffect, useState } from "react";
import { SORT_DIRECTIONS } from "common/constants";
import { Content } from "antd/lib/layout/layout";
const { Search } = Input;

export const Brands = () => {
    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        fetchBrands();
    }, []);

    const fetchBrands = async () => {
        setLoading(true);
        getBrands()
            .then((brands) => {
                setBrands(brands);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onSearch = (value) => {
        setSearchText(value);
    };

    const onChangeSearch = (e) => {
        onSearch(e?.target?.value);
    };

    let dataSource = brands;
    if (searchText) {
        dataSource = brands?.filter(
            (b) =>
                b?.fullName
                    ?.toLowerCase()
                    .startsWith(searchText.toLowerCase()) ||
                b?.email?.toLowerCase().startsWith(searchText.toLowerCase()) ||
                b?.bizNameOrFullName
                    .toLowerCase()
                    .startsWith(searchText.toLowerCase()) ||
                b?.userId
                    .toString()
                    .toLowerCase()
                    .startsWith(searchText.toLowerCase()),
        );
    }

    return (
        <Content style={{ padding: 24 }}>
            <Search
                autoFocus={true}
                allowClear
                enterButton={false}
                onChange={onChangeSearch}
                onSearch={onSearch}
                placeholder="Search by name and email"
                style={{ width: 300, marginBottom: 24 }}
            />
            <Table
                bordered
                columns={[...brandsColumns]}
                dataSource={dataSource}
                loading={loading}
                pagination={{
                    position: ["bottomCenter"],
                    hideOnSinglePage: true,
                    pageSizeOptions: [20, 50, 100],
                    defaultPageSize: 20,
                    // size: "default",
                    showTotal: (total) => `Total ${total} items`,
                }}
                rowKey="userId"
                scroll={{
                    scrollToFirstRowOnChange: true,
                    y: "calc(100vh - 180px)",
                    x: 1000,
                }}
                size="small"
                sortDirections={SORT_DIRECTIONS}
                style={{ width: "100%", height: "100%" }}
            />
        </Content>
    );
};
