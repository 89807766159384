import { booleanSubscriptionLimits } from "common/constants";
import { fromUTC } from "common/utils";
import { format } from "date-fns";
import { columnSorterFunc } from "../../brands/brand_columns";

export const sortDatesWithNullValues = (a, b) => {
    const minDate = new Date(1970);

    let dateA = a[END_DATE.dataIndex]
        ? new Date(a[END_DATE.dataIndex])
        : minDate;
    let dateB = b[END_DATE.dataIndex]
        ? new Date(b[END_DATE.dataIndex])
        : minDate;
    return dateA.getTime() - dateB.getTime();
};

export const TYPE = {
    dataIndex: "type",
    title: "Type",
};
export const SUBSCRIPTION_LIMIT = {
    dataIndex: "limit",
    title: "Subscription Limit (lifetime/monthly)",
};
export const END_DATE = {
    dataIndex: "endDate",
    title: "End Date",
};

export const columnDef_type = {
    title: TYPE.title,
    dataIndex: TYPE.dataIndex,
    key: TYPE.dataIndex,
    width: 390,
    render: ({ descr }) => descr,
    sorter: (a, b) => columnSorterFunc(a?.type, b?.type, "descr"),
};

export const columnDef_subscriptionLimit = {
    align: "center",
    title: SUBSCRIPTION_LIMIT.title,
    dataIndex: SUBSCRIPTION_LIMIT.dataIndex,
    key: SUBSCRIPTION_LIMIT.dataIndex,
    render: (limit, item) => {
        if (booleanSubscriptionLimits.includes(item.type.id)) {
            return limit > 0 ? "On" : "Off";
        }

        if (limit === 0) {
            return "Unlimited";
        }

        if (limit < 0) {
            return "Off";
        }

        return limit;
    },
    sorter: (a, b) => columnSorterFunc(a, b, SUBSCRIPTION_LIMIT.dataIndex),
    width: 240,
};

export const columnDef_endDate = {
    align: "center",
    title: END_DATE.title,
    dataIndex: END_DATE.dataIndex,
    key: END_DATE.dataIndex,
    render: (endDate) => !!endDate && format(fromUTC(endDate), "MM/dd/yyyy"),
    sorter: (a, b) => sortDatesWithNullValues(a, b),
    width: 240,
};

export const subscription_columns = [
    columnDef_type,
    columnDef_subscriptionLimit,
    columnDef_endDate,
];
