import { useState } from "react";
import {
    InfoCircleOutlined,
    SaveOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {
    Row,
    Col,
    Avatar,
    Statistic,
    Button,
    Space,
    Form,
    Input,
    Select,
    Alert,
    Tooltip,
    message,
} from "antd";
import {
    USER_STATUS_ACTIVE,
    USER_STATUS_CLOSED,
    USER_STATUS_PAUSED,
    USER_SETTING_SALES_CRM_COMPANY_ID,
    BRAND_TYPE_INTERNAL,
    BRAND_TYPE_DEMO,
} from "common/types";
import { brandTypes } from "common/constants";
import { updateBrand } from "components/brands/brands.api";

const statusOptions = [
    { value: USER_STATUS_ACTIVE, label: "Active" },
    { value: USER_STATUS_PAUSED, label: "Paused" },
    { value: USER_STATUS_CLOSED, label: "Closed" },
];

export const Profile = ({ brandUser, fetchBrand }) => {
    const {
        userId,
        businessName = brandUser.brand.name,
        fullName,
        imageUri,
        email,
        systemUser,
        statusId = brandUser.brand.statusId,
        brandTypeId = brandUser.brand.typeId,
        settings,
    } = brandUser || {};

    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);

    const salesCrmCompanyIdSetting = settings?.find(
        (setting) => setting.typeId === USER_SETTING_SALES_CRM_COMPANY_ID,
    );

    const handleSave = (values) => {
        setIsLoading(true);
        const { statusId, brandTypeId, salesCrmCompanyId } = values;

        const brand = {
            ...brandUser.brand,
            statusId: statusId,
            typeId: brandTypeId,
        };

        const settings = [
            {
                id: salesCrmCompanyIdSetting?.id,
                typeId: USER_SETTING_SALES_CRM_COMPANY_ID,
                value: salesCrmCompanyId,
            },
        ];

        updateBrand({ ...brandUser, statusId, brand, settings })
            .then(() => {
                message.success(`Profile updated for ${businessName}.`);
                fetchBrand(userId);
            })
            .catch((err) => {
                message.warning(
                    "An unhandled error occurred. Please try again. If this continues, contact our support team.",
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const statusTooltip = (
        <>
            This status syncs with Chargebee and to Hubspot. The descriptions
            are:
            <br />
            <br />
            <b>Active:</b>
            &nbsp; brand team members have login access to the account
            <br />
            <br />
            <b>Paused:</b> blocks account access, backend processing / social
            listening continues
            <br />
            <br />
            <b>Closed:</b> blocks account access, stops backend processing /
            social listening
        </>
    );

    return (
        <Row className="profile" gutter={[0, 48]}>
            {statusId === USER_STATUS_CLOSED && (
                <Alert
                    style={{ width: "100%" }}
                    message="Closed accounts stop all data processing. When reactivating a brand, they will need to reconnect with their 3rd-party integrations to resume processing."
                    type="warning"
                    showIcon
                />
            )}
            {!salesCrmCompanyIdSetting?.value &&
                statusId !== USER_STATUS_CLOSED &&
                brandTypeId !== BRAND_TYPE_INTERNAL &&
                brandTypeId !== BRAND_TYPE_DEMO && (
                    <Alert
                        style={{ width: "100%" }}
                        message="This account is not connected to Hubspot. Enter the Hubspot Company ID to enable recurring data syncs."
                        type="warning"
                        showIcon
                    />
                )}
            <Col flex="200px">
                <Avatar
                    src={imageUri}
                    size={150}
                    alt="avatar"
                    icon={<UserOutlined />}
                />
            </Col>
            <Col flex="auto">
                <Row gutter={[0, 48]}>
                    <Col flex="auto">
                        <Space direction="vertical" size="middle">
                            <Statistic
                                title="Brand ID"
                                formatter={(x) => x}
                                value={userId}
                            />
                            <Statistic
                                title="Brand Name"
                                value={businessName}
                            />
                            <Statistic
                                title={
                                    <>
                                        Ambassador Portal Domain{" "}
                                        <Tooltip title="https://roster.link/{domain}">
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </>
                                }
                                value={systemUser?.userDomain?.domain}
                            />
                            {/* <Statistic title="Name" value={fullName} />
                            <Statistic
                                title="Email"
                                formatter={(mail) => (
                                    <a href={`mailto:${mail}`}>{mail}</a>
                                )}
                                value={email}
                            /> */}
                        </Space>
                    </Col>
                    <Col flex="auto">
                        <Space direction="vertical" size="middle">
                            <Form
                                className="brand-profile-form"
                                form={form}
                                autoComplete="off"
                                layout="vertical"
                                disabled={isLoading}
                                onFinish={handleSave}
                                validateTrigger={false}>
                                <Row wrap={false}>
                                    <Col flex="auto">
                                        <Form.Item
                                            label="Account Status"
                                            name="statusId"
                                            tooltip={statusTooltip}
                                            initialValue={statusId}>
                                            <Select options={statusOptions} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Account Type"
                                            name="brandTypeId"
                                            tooltip="Indicates if this is a brand customer or an internal test account."
                                            initialValue={brandTypeId}>
                                            <Select options={brandTypes} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Hubspot Company ID"
                                            name="salesCrmCompanyId"
                                            initialValue={
                                                salesCrmCompanyIdSetting?.value ??
                                                null
                                            }
                                            tooltip="Required for syncing data to Hubspot. Found in the URL in Hubspot on the company page. (ie: .../company/{companyId}"
                                            rules={[
                                                {
                                                    validator(_, value) {
                                                        if (
                                                            !value ||
                                                            (value &&
                                                                value.match(
                                                                    /^[0-9-]+$/,
                                                                ))
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            new Error(
                                                                "The company ID must be numeric.",
                                                            ),
                                                        );
                                                    },
                                                },
                                            ]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item className="button-form-item">
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                icon={<SaveOutlined />}>
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
