import { CookiesProvider } from "react-cookie";
import DocumentTitle from "react-document-title";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { setupDAL } from "api";
import App from "./App";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import { defaultTheme } from "theme";

setupDAL();

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

root.render(
    <QueryClientProvider client={queryClient}>
        <CookiesProvider>
            <ConfigProvider theme={defaultTheme}>
                <DocumentTitle title="Roster Administration Portal">
                    <div className="App">
                        <div className="flex-container content-wrapper">
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </div>
                    </div>
                </DocumentTitle>
            </ConfigProvider>
        </CookiesProvider>
    </QueryClientProvider>,
);
