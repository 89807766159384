import axios from "axios";

export const getUserTasks = async (userId) => {
    return axios
        .get(`user/task/${userId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error("Failed to get Tasks");
            console.error(error);
        });
};

export const updateUserTasks = async (userId, task) => {
    return axios
        .post(`user/task/${userId}`, task)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error("Failed to create/update Tasks");
            console.error(error);
        });
};
