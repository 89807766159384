import DocumentTitle from "react-document-title";
import { Navigate, Route, Routes } from "react-router-dom";

import PageLayout from "components/layout/Layout";
import { Login } from "components/login/Login";
import { Brands } from "components/brands/Brands";

import { RequireAuth } from "contexts/AuthContext";
import { NewBrandForm } from "components/NewBrandForm";
import { BrandWrapper } from "components/brand/Brand";

import "App.scss";

const App = () => (
    <Routes>
        <Route
            element={
                <RequireAuth>
                    <PageLayout />
                </RequireAuth>
            }>
            <Route path="brands">
                <Route
                    index
                    element={
                        <DocumentTitle title="Brands | Roster Administration Portal">
                            <Brands />
                        </DocumentTitle>
                    }
                />
                <Route path=":userId" element={<BrandWrapper />}>
                    <Route path=":tabId" element={<></>} />
                </Route>
            </Route>
            <Route
                path="new"
                element={
                    <DocumentTitle title="Add New Brand | Roster Administration Portal">
                        <NewBrandForm />
                    </DocumentTitle>
                }
            />
            <Route path="*" element={<Navigate to="brands" replace={true} />} />
        </Route>
        <Route path="login" element={<Login />} />
    </Routes>
);

export default App;
