import { Form, InputNumber, Radio } from "antd";
import { booleanSubscriptionLimits } from "common/constants";
import { useState } from "react";

export const Limit = ({ typeId, initialValue }) => {
    const [value, setValue] = useState(initialValue);

    const onRadioValueChange = (evt) => {
        setValue(evt.target.value);
    };

    if (booleanSubscriptionLimits.includes(typeId)) {
        return (
            <Form.Item initialValue={initialValue} name="limit">
                <Radio.Group
                    buttonStyle="solid"
                    value={value}
                    onChange={onRadioValueChange}>
                    <Radio.Button value={1}>On</Radio.Button>
                    <Radio.Button value={-1}>Off</Radio.Button>
                </Radio.Group>
            </Form.Item>
        );
    }

    return (
        <>
            <Form.Item label="Limit" initialValue={initialValue} name="limit">
                <Radio.Group
                    buttonStyle="solid"
                    value={value}
                    onChange={onRadioValueChange}>
                    <Radio.Button value={value > 0 ? value : 1}>
                        Custom
                    </Radio.Button>
                    <Radio.Button value={0}>Unlimited</Radio.Button>
                    <Radio.Button value={-1}>Off</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                initialValue={initialValue > 0 ? initialValue : 1}
                name="limitCustom">
                {value > 0 && <InputNumber min={1} placeholder="1" />}
            </Form.Item>
        </>
    );
};
