import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Form, message, Modal, Row, Select } from "antd";

import { getLookupsByType } from "common/api";
import { LOOKUP_SUBSCRIPTION_ITEM } from "common/types";
import { fromUTC, toUTC } from "common/utils";

import DatePicker from "common/DatePicker";

import { updateUserSubscription } from "./subscription.api";
import { Limit } from "./Limit";
import { booleanSubscriptionLimits } from "common/constants";

export const SubscriptionItemModal = ({
    isOpen,
    subscription,
    subscriptionItem = {},
    onCancel = () => {},
    onSuccess = () => {},
    onItemChange = () => {},
}) => {
    const { userId } = useParams();

    const [itemTypes, setItemTypes] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        getLookupsByType(LOOKUP_SUBSCRIPTION_ITEM).then((t) => setItemTypes(t));
    }, []);

    const onOk = (values) => {
        const { typeId, limit, endDate, limitCustom } = values;
        setIsLoading(true);

        subscriptionItem.type = { id: typeId };
        subscriptionItem.limit =
            !booleanSubscriptionLimits.includes(typeId) && limit >= 1
                ? limitCustom
                : limit;

        subscriptionItem.endDate = toUTC(endDate);

        updateUserSubscription(userId, {
            ...subscription,
            items: [...subscription?.items, subscriptionItem],
        })
            .then((data) => {
                if (data.success) {
                    onCancel();
                    onSuccess();
                } else {
                    message.error(
                        data.message ||
                            `Failed to ${
                                subscriptionItem?.type?.id ? "update" : "create"
                            } subscription item`,
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    let itemOptions = [];
    if (!!itemTypes?.length) {
        itemOptions = itemTypes
            ?.filter(
                (type) =>
                    type?.value === null || JSON.parse(type.value)?.visible,
            )
            .map((type) => ({
                value: type.id,
                label: type.descr,
            }));
    }

    return (
        <Modal
            open={isOpen}
            title={
                subscriptionItem?.type?.id
                    ? "Edit Subscription Item"
                    : "Add New Subscription Item"
            }
            okText="Save"
            onCancel={onCancel}
            width={700}
            destroyOnClose
            okButtonProps={{
                loading: isLoading,
                form: "subscription-item-form",
                key: "submit",
                htmlType: "submit",
            }}>
            <Form
                id="subscription-item-form"
                form={form}
                layout="vertical"
                onFinish={onOk}
                validateTrigger={false}
                preserve={false}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Item Type"
                            name="typeId"
                            initialValue={subscriptionItem?.type?.id}>
                            <Select
                                options={itemOptions}
                                onChange={(val) => onItemChange(val)}
                            />
                        </Form.Item>
                        <Limit
                            key={subscriptionItem?.type?.id}
                            initialValue={subscriptionItem?.limit}
                            typeId={subscriptionItem?.type?.id}
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="EndDate"
                            name="endDate"
                            initialValue={fromUTC(subscriptionItem?.endDate)}>
                            <DatePicker />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
