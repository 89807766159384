import { DESCEND } from "common/constants";
import { LoginAsButton } from "common/LoginAsButton";
import { Link } from "react-router-dom";

export const columnSorterFunc = (a, b, field) => {
    if (!a && b) {
        return -1;
    }
    if (a && !b) {
        return 1;
    }
    if (!a && !b) {
        return 0;
    }
    if (typeof a[field] === "number") {
        return a[field] - b[field];
    }
    return a[field]?.localeCompare(b[field]);
};

export const USERID = {
    dataIndex: "userId",
    title: "Id",
};
export const STATUS_DESCR = {
    dataIndex: "statusDescr",
    title: "Status",
};
export const ROLE = {
    dataIndex: "role",
    title: "Role",
};
export const FULL_NAME = {
    dataIndex: "fullName",
    title: "Full Name",
};
export const BIZ_NAME = {
    dataIndex: "businessName",
    title: "Business Name",
};
export const EMAIL = {
    dataIndex: "email",
    title: "Email",
};
export const INSERT_DATE = {
    dataIndex: "insertDate",
    title: "Reg. Date",
};

export const columnDef_userId = {
    title: USERID.title,
    dataIndex: USERID.dataIndex,
    key: USERID.dataIndex,
    width: "90px",
    defaultSortOrder: DESCEND,
    sorter: (a, b) => columnSorterFunc(a, b, USERID.dataIndex),
    render: (text, record) =>
        record[ROLE.dataIndex] === "Team Member" || !record.databaseActive ? (
            <>{text}</>
        ) : (
            <Link to={`${text}`} title={`Database: ${record.databaseName}`}>
                {text}
            </Link>
        ),
};
export const columnDef_status = {
    title: STATUS_DESCR.title,
    dataIndex: STATUS_DESCR.dataIndex,
    key: STATUS_DESCR.dataIndex,
    width: "130px",
    sorter: (a, b) => columnSorterFunc(a, b, STATUS_DESCR.dataIndex),
    filters: [
        { text: "All", value: "All" },
        { text: "Active", value: "Active" },
        { text: "Paused", value: "Paused" },
        { text: "Closed", value: "Closed" },
    ],
    defaultFilteredValue: ["All"],
    onFilter: (value, record) => {
        if (value === "All") {
            return true;
        }
        return record[STATUS_DESCR.dataIndex]
            ?.toString()
            .toLowerCase()
            .includes(value?.toLowerCase());
    },
};
export const columnDef_role = {
    title: ROLE.title,
    dataIndex: ROLE.dataIndex,
    key: ROLE.dataIndex,
    width: "130px",
    sorter: (a, b) => columnSorterFunc(a, b, ROLE.dataIndex),
    filters: [
        { text: "Owner", value: "Owner" },
        { text: "Team Member", value: "Team Member" },
    ],
    defaultFilteredValue: ["Owner"],
    onFilter: (value, record) =>
        record[ROLE.dataIndex]
            ?.toString()
            .toLowerCase()
            .includes(value?.toLowerCase()),
};
export const columnDef_fullName = {
    title: FULL_NAME.title,
    dataIndex: FULL_NAME.dataIndex,
    key: FULL_NAME.dataIndex,
    sorter: (a, b) => columnSorterFunc(a, b, FULL_NAME.dataIndex),
    render: (text, record) => (
        <div className="flex-container space-between">
            {text}
            <LoginAsButton
                type="link"
                size="small"
                style={{ padding: 0, marginLeft: "auto" }}
                userId={record.userId}
            />
        </div>
    ),
};
export const columnDef_bizName = {
    title: BIZ_NAME.title,
    dataIndex: BIZ_NAME.dataIndex,
    key: BIZ_NAME.dataIndex,
    sorter: (a, b) => columnSorterFunc(a, b, BIZ_NAME.dataIndex),
};
export const columnDef_email = {
    title: EMAIL.title,
    dataIndex: EMAIL.dataIndex,
    key: EMAIL.dataIndex,
    sorter: (a, b) => columnSorterFunc(a, b, EMAIL.dataIndex),
};
export const columnDef_insertDate = {
    title: INSERT_DATE.title,
    dataIndex: INSERT_DATE.dataIndex,
    key: INSERT_DATE.dataIndex,
    sorter: (a, b) => columnSorterFunc(a, b, INSERT_DATE.dataIndex),
    render: (text) => (text ? new Date(text).toLocaleDateString() : null),
    width: "100px",
};

export const brandsColumns = [
    columnDef_userId,
    columnDef_bizName,
    columnDef_fullName,
    columnDef_email,
    columnDef_role,
    columnDef_status,
    columnDef_insertDate,
];
