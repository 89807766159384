import axios from "axios";

export const getSubscriptions = async (id) => {
    return axios
        .get(`subscription/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error("Failed to get subscriptions");
            console.error(error);
        });
};

export const getUserSubscription = async (userId) => {
    return axios
        .get(`admin/user/${userId}/subscription`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error("Failed to get user subscription");
            console.error(error);
        });
};

export const updateUserSubscription = async (userId, subscription) => {
    return axios
        .post(`admin/user/${userId}/subscription`, subscription)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error("Failed to create/update subscription");
            console.error(error);
        });
};
