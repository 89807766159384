import FeatureFlagToggle from "./FeatureFlagToggle";

export const ID = {
    dataIndex: "id",
    title: "Internal ID",
};
export const NAME = {
    dataIndex: "name",
    title: "Name",
};
export const STATUS = {
    dataIndex: "brandStatus",
    title: "Status",
};

export const columnDef_id = {
    title: ID.title,
    dataIndex: ID.dataIndex,
    key: ID.dataIndex,
    width: 64,
};

export const columnDef_name = {
    title: NAME.title,
    dataIndex: NAME.dataIndex,
    key: NAME.dataIndex,
    width: 340,
};

export const columnDef_status = {
    title: STATUS.title,
    dataIndex: STATUS.dataIndex,
    key: STATUS.dataIndex,
    width: 340,
    render: (status, { id, defaultStatus }) => {
        return (
            <FeatureFlagToggle
                status={status}
                id={id}
                defaultStatus={defaultStatus}
            />
        );
    },
};

export const columnDefs_featureFlags = [
    columnDef_id,
    columnDef_name,
    columnDef_status,
];
