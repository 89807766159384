import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
    Alert,
    Button,
    Col,
    Descriptions,
    Divider,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Space,
    Table,
    Tooltip,
    message,
    Spin,
    Typography,
} from "antd";
import { format } from "date-fns";
import partial from "lodash/partial";

import { getLookupsByType } from "common/api";
import {
    getSubscriptions,
    getUserSubscription,
    updateUserSubscription,
} from "./subscription.api";

import { fromUTC } from "common/utils";
import {
    LOOKUP_BILL_FREQUENCY,
    LOOKUP_SUBSCRIPTION_STATUS,
} from "common/types";
import { subscription_columns } from "./subscription_columns";
import { columnDef_fixedRightAction, SORT_DIRECTIONS } from "common/constants";
import DatePicker from "common/DatePicker";
import { SubscriptionItemModal } from "./SubscriptionItemModal";

import {
    EditOutlined,
    QuestionCircleOutlined,
    PlusCircleOutlined,
    SaveOutlined,
} from "@ant-design/icons";
import "./Subscription.scss";

const { Item } = Descriptions;
const { Link } = Typography;

const labelStyle = { fontWeight: "var(--font-weight-semibold)" };

export const Subscription = () => {
    const { userId } = useParams();

    const [userSubscription, setUserSubscription] = useState({});
    const [loading, setLoading] = useState(true);

    const [tierOptions, setTierOptions] = useState([]);
    const [subscriptionStatuses, setSubscriptionStatuses] = useState([]);
    const [billFrequencyTypes, setBillFrequencyTypes] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedSubscriptionItem, setSubscriptionItem] = useState(null);

    const [form] = Form.useForm();

    useEffect(() => {
        fetchUserSubscription(false);
        fetchTierOptions();
        getLookupsByType(LOOKUP_SUBSCRIPTION_STATUS).then((t) =>
            setSubscriptionStatuses(
                t?.map((status) => ({
                    ...status,
                    label: status.descr,
                    value: status.id,
                })),
            ),
        );
        getLookupsByType(LOOKUP_BILL_FREQUENCY).then((t) =>
            setBillFrequencyTypes(
                t?.map((type) => ({
                    ...type,
                    label: type.descr,
                    value: type.id,
                })),
            ),
        );
    }, []);

    const fetchUserSubscription = (bindForm) => {
        setLoading(true);

        getUserSubscription(userId)
            .then((data) => {
                setUserSubscription(data.result ?? {});

                if (bindForm) {
                    // Rebind the form on save so the user sees which data saved or was overwritten from the 3rd party billing system sync
                    const formValues = {
                        billFrequencyTypeId: data.result?.billFrequencyType?.id,
                        commissionRate: data.result?.commissionRate,
                        endDate: data.result?.endDate,
                        externalBillingSystemCustomerId:
                            data.result?.externalBillingSystemCustomerId,
                        recurringRate: data.result?.recurringRate,
                        statusId: data.result?.status?.id,
                        subscriptionId: data.result?.subscription?.id,
                        trialEndDate: data.result?.trialEndDate,
                    };

                    form.setFieldsValue(formValues);
                }

                if (!data.success && data.message) {
                    message.warning(data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchTierOptions = () => {
        getSubscriptions(0).then((data) => {
            setTierOptions(
                data.result?.map((tier) => ({
                    ...tier,
                    value: tier.id,
                    label: tier.name,
                })) ?? [],
            );
        });
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setSubscriptionItem(null);
    };

    const handleOpenModal = (subscriptionItem) => {
        setIsOpen(true);
        setSubscriptionItem(subscriptionItem);
    };

    const onItemChange = (typeId) => {
        setSubscriptionItem(
            userSubscription?.items.find((s) => s.type.id === typeId),
        );
    };

    const handleSave = (values) => {
        setLoading(true);

        const {
            billFrequencyTypeId,
            commissionRate,
            endDate,
            externalBillingSystemCustomerId,
            recurringRate,
            statusId,
            subscriptionId,
            trialEndDate,
        } = values;

        updateUserSubscription(userId, {
            ...userSubscription,
            id:
                // We need to tell backend to create a new subscription
                // on a tier change.
                userSubscription.subscription.id !== subscriptionId
                    ? 0
                    : userSubscription.id,
            commissionRate: commissionRate ?? 0,
            endDate,
            trialEndDate,
            externalBillingSystemCustomerId,
            recurringRate: recurringRate ?? 0,
            billFrequencyType: { id: billFrequencyTypeId },
            status: { id: statusId },
            subscription: { id: subscriptionId },
        })
            .then((response) => {
                if (response.success) {
                    message.success(
                        "The brand's subscription updated successfully",
                    );

                    fetchUserSubscription(true);
                } else {
                    setLoading(false);
                }
            })
            .catch((err) => {
                message.warning(
                    "An unhandled error occurred. Please try again. If this continues, contact our support team.",
                );
                setLoading(false);
            });
    };

    const isFormDisabled = !!userSubscription?.externalBillingSystemCustomerId;

    // Subscription item action button
    const columnDef_action = {
        ...columnDef_fixedRightAction,
        render: (_, record) => (
            <Button
                type="link"
                size="small"
                shape="circle"
                icon={<EditOutlined />}
                onClick={partial(handleOpenModal, record)}
                disabled={isFormDisabled}
            />
        ),
    };

    return (
        <Spin spinning={loading}>
            {!userSubscription?.externalBillingSystemCustomerId && (
                <Alert
                    className="form-alert"
                    message="This account is not connected to a billing system. Enter the Chargebee Customer ID."
                    type="warning"
                    showIcon
                />
            )}

            <Descriptions>
                <Item label="Subscription ID" labelStyle={labelStyle}>
                    {userSubscription?.id}
                </Item>

                <Item label="Create Date" labelStyle={labelStyle}>
                    {userSubscription?.insertDate &&
                        format(
                            fromUTC(userSubscription?.insertDate),
                            "MM/dd/yyyy",
                        )}
                </Item>
                <Item label="Billing Start Date" labelStyle={labelStyle}>
                    <Space>
                        {!!userSubscription?.billingStartDate &&
                            format(
                                fromUTC(userSubscription?.billingStartDate),
                                "MM/dd/yyyy",
                            )}
                        <Tooltip
                            placement="top"
                            title="Is set when user registers and is automatically updated each month. This date controls subscription limits on processing tasks.">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </Space>
                </Item>
            </Descriptions>
            {!!userSubscription?.id && (
                <Form
                    className="subscription-form"
                    form={form}
                    autoComplete="off"
                    disabled={loading}
                    layout="vertical"
                    onFinish={handleSave}
                    validateTrigger={false}>
                    <Row wrap={false}>
                        <Col span={8} flex="auto">
                            <Form.Item
                                label="Tier"
                                name="subscriptionId"
                                initialValue={
                                    userSubscription?.subscription?.id
                                }>
                                <Select
                                    options={tierOptions}
                                    disabled={isFormDisabled}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Status"
                                name="statusId"
                                initialValue={userSubscription?.status?.id}
                                tooltip="Syncs with Chargebee statuses.">
                                <Select
                                    disabled={isFormDisabled}
                                    options={subscriptionStatuses}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Chargebee Customer ID"
                                name="externalBillingSystemCustomerId"
                                initialValue={
                                    userSubscription?.externalBillingSystemCustomerId
                                }
                                tooltip="The customer ID for the integrated 3rd party billing system. If you enter an ID when a billing system is not already connected, the system will default to Chargebee."
                                extra={
                                    userSubscription?.externalBillingSystem
                                        ?.id ? (
                                        <div style={{ fontSize: 12 }}>
                                            {userSubscription?.externalBillingSystemCustomerId ? (
                                                <div>
                                                    <Link
                                                        href={`https://${process.env.REACT_APP_CHARGEBEE_SITE}.chargebee.com/d/customers/${userSubscription.externalBillingSystemCustomerId}`}
                                                        rel="noopener noreferrer"
                                                        target="_blank">
                                                        Manage in Chargebee
                                                    </Link>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            External Billing System:{" "}
                                            {
                                                userSubscription
                                                    .externalBillingSystem.descr
                                            }
                                            <br />
                                            {
                                                userSubscription
                                                    .externalBillingSystem.descr
                                            }{" "}
                                            Subscription ID:{" "}
                                            {userSubscription.externalBillingSystemSubscriptionId ??
                                                "n/a"}
                                        </div>
                                    ) : (
                                        "Billing system not connected"
                                    )
                                }>
                                <Input disabled={isFormDisabled} />
                            </Form.Item>
                        </Col>
                        <Col span={8} flex="auto">
                            <Form.Item
                                label="Recurring Rate"
                                name="recurringRate"
                                initialValue={userSubscription?.recurringRate}>
                                <InputNumber
                                    style={{ width: "100%" }}
                                    addonBefore="$"
                                    min={0}
                                    disabled={isFormDisabled}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Billing Frequency"
                                name="billFrequencyTypeId"
                                initialValue={
                                    userSubscription?.billFrequencyType?.id
                                }>
                                <Select
                                    options={billFrequencyTypes}
                                    disabled={isFormDisabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="End Date"
                                name="endDate"
                                tooltip="If end date is < current date this ends the brand's subscription and they lose access to their account."
                                initialValue={
                                    userSubscription?.endDate
                                        ? new Date(userSubscription?.endDate)
                                        : null
                                }>
                                <DatePicker disabled placeholder="mm/dd/yyyy" />
                            </Form.Item>
                            <Form.Item
                                label="Trial End Date"
                                name="trialEndDate"
                                tooltip="If trial end date is < current date this ends the client's trial and billing will commence in the integrated billing system."
                                initialValue={
                                    userSubscription?.trialEndDate
                                        ? new Date(
                                              userSubscription?.trialEndDate,
                                          )
                                        : null
                                }>
                                <DatePicker disabled placeholder="mm/dd/yyyy" />
                            </Form.Item>

                            <Form.Item className="button-form-item">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    icon={<SaveOutlined />}
                                    disabled={isFormDisabled}>
                                    Save
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}
            <Divider style={{ marginTop: 0 }} />
            <Space className="subscription-items-title">
                <div className="add-item-btn">Subscription Items</div>
                <Button
                    icon={<PlusCircleOutlined />}
                    onClick={partial(handleOpenModal, {
                        userSubscriptionId: userSubscription?.id,
                    })}
                    disabled={isFormDisabled}
                    type="link">
                    Add New Item
                </Button>
            </Space>
            <Table
                bordered
                columns={[...subscription_columns, columnDef_action]}
                dataSource={
                    userSubscription?.items?.filter((it) => it.isVisible) ?? []
                }
                loading={loading}
                pagination={{
                    position: ["bottomCenter"],
                    hideOnSinglePage: true,
                    pageSize: 15,
                }}
                rowKey={(record) => record.type.id}
                size="small"
                sortDirections={SORT_DIRECTIONS}
            />
            <SubscriptionItemModal
                isOpen={isOpen}
                subscription={userSubscription}
                subscriptionItem={selectedSubscriptionItem}
                onCancel={handleCloseModal}
                onSuccess={fetchUserSubscription}
                onItemChange={onItemChange}
            />
        </Spin>
    );
};
