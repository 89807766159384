import axios from "axios";

export const getLookupsByType = async (lookupType) => {
    return axios
        .get(`lookup/lookup-type/${lookupType}`)
        .then((response) => {
            return response.data.result;
        })
        .catch((error) => {
            console.error("Failed to get Lookup types");
            console.error(error);
        });
};
