import { BRAND_TYPE_DEMO } from "./types";

export const ASCEND = "ascend";
export const DESCEND = "descend";

export const SORT_DIRECTIONS = [DESCEND, ASCEND, DESCEND];

export const requiredFormRule = {
    required: true,
    message: "This field is required!",
    whitespace: true,
};

export const requiredFormRuleNumeric = {
    required: true,
    message: "This field is required!",
};

export const SESSION_KEY = "userSession";
export const ADMIN_SESSION_KEY = "adminSession";
export const ADMIN_APP_SESSION_KEY = `${
    window.location.hostname.split(".")[0]
}_adminSession`;

export const defaultCookieOptions = {
    domain: process.env.REACT_APP_DOMAIN,
    path: "/",
    secure: true,
    sameSite: "none",
};

//right fixed action collumn
export const columnDef_fixedRightAction = {
    fixed: "right",
    width: "50px",
    title: "",
};

export const SOCIAL_LISTENING_ENABLED = 659;
export const AUTO_CREATE_CONTACTS_FROM_DATA_SYNC = 477;
export const AUTO_CREATE_CONTACTS_FROM_SOCIAL_LISTENIGN = 475;

export const booleanSubscriptionLimits = [
    SOCIAL_LISTENING_ENABLED,
    AUTO_CREATE_CONTACTS_FROM_DATA_SYNC,
    AUTO_CREATE_CONTACTS_FROM_SOCIAL_LISTENIGN,
];

export const BRAND_TYPE_CUSTOMER = 946;
export const BRAND_TYPE_INTERNAL = 947;

export const brandTypes = [
    { label: "Customer", value: BRAND_TYPE_CUSTOMER },
    { label: "Internal", value: BRAND_TYPE_INTERNAL },
    { label: "Demo", value: BRAND_TYPE_DEMO },
];
