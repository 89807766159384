import { ADMIN_APP_SESSION_KEY, defaultCookieOptions } from "common/constants";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";

export const RequireAuth = ({ children }) => {
    const [cookies, setCookies] = useCookies([ADMIN_APP_SESSION_KEY]);

    // check if there no user or admin session passed from BP
    if (!cookies[ADMIN_APP_SESSION_KEY] && !cookies.userSession) {
        return <Navigate to="/login" replace />;
    }

    //sync session data
    if (
        cookies[ADMIN_APP_SESSION_KEY]?.userId !== cookies.userSession?.userId
    ) {
        //if there no adminSession than copy it from userSession
        if (!cookies[ADMIN_APP_SESSION_KEY]) {
            setCookies(ADMIN_APP_SESSION_KEY, cookies.userSession, {
                ...defaultCookieOptions,
            });
        }
    }

    return children;
};
