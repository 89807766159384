import {
    useQuery as useTanstackQuery,
    useMutation as useTanstackMutation,
    useQueryClient,
} from "@tanstack/react-query";

export const useRequestProcessor = () => {
    const queryClient = useQueryClient();

    const useQuery = (key, queryFunction, options = {}) => {
        return useTanstackQuery({
            queryKey: key,
            queryFn: queryFunction,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
    };

    const useMutate = (key, mutationFunction, options = {}) => {
        return useTanstackMutation({
            mutationKey: key,
            mutationFn: mutationFunction,
            onSettled: () => queryClient.invalidateQueries({ queryKey: key }),
            ...options,
        });
    };

    return { useQuery, useMutate };
};
