import { Alert, Space, Table } from "antd";
import {
    useGetBrandFeatureFlagStatuses,
    useGetFeatureFlags,
} from "hooks/useGetFeatureFlags";
import { columnDefs_featureFlags } from "./feature-flag.columns";
import { useGetAdminSession } from "hooks/useGetUserSession";

export const FEATURE_FLAG_PERMISSION_WHITELIST =
    process.env.REACT_APP_ENV === "production"
        ? [
              1069798, // Daniel
              1068741, // James
              1069004, // Jeff
              1069438, // Kyle
              1068765, // Libby
              1122716, // Caroline
          ]
        : [];

const FeatureFlags = ({ brandUserId }) => {
    const { data: featureFlagList, isLoading: featureflagListLoading } =
        useGetFeatureFlags();

    const {
        data: brandFeatureFlagStatuses,
        isLoading: brandFeatureFlagStatusesLoading,
    } = useGetBrandFeatureFlagStatuses(brandUserId);

    const userSession = useGetAdminSession();

    const tableData = featureFlagList?.map((flag) => {
        const brandStatus = brandFeatureFlagStatuses?.find(
            (status) => status.featureFlagId === flag.id,
        );

        return {
            ...flag,
            brandStatus: brandStatus?.status,
        };
    });

    const canEdit =
        FEATURE_FLAG_PERMISSION_WHITELIST?.length > 0
            ? FEATURE_FLAG_PERMISSION_WHITELIST.includes(
                  userSession?.sessionUserId,
              )
            : true;

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {!canEdit && (
                <Alert
                    message="You do not have permissions to make changes on this page."
                    type="warning"
                    showIcon
                />
            )}
            <Table
                bordered
                columns={[...columnDefs_featureFlags]}
                dataSource={tableData || []}
                loading={
                    featureflagListLoading || brandFeatureFlagStatusesLoading
                }
                pagination={{
                    position: ["bottomCenter"],
                    hideOnSinglePage: true,
                    pageSize: 15,
                }}
                rowKey={(record) => record.id}
                size="small"
            />
        </Space>
    );
};

export default FeatureFlags;
