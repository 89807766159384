import { PageHeader } from "@ant-design/pro-layout";
import { Card, message, Tabs } from "antd";
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { LoginAsButton } from "common/LoginAsButton";
import { Profile } from "./Profile";
import { Tasks } from "./tasks/Tasks";
import { Subscription } from "./subscription/Subscription";
import { getBrand } from "../brands/brands.api";
import { UserOutlined } from "@ant-design/icons";

import "./Brand.scss";
import { Content } from "antd/lib/layout/layout";
import FeatureFlags from "./feature-flags/FeatureFlags";

const { TabPane } = Tabs;

const PROFILE = "profile";
const TASKS = "tasks";
const SUBSCRIPTION = "subscription";
const FEATURE_FLAGS = "feature-flags";

const tabList = [PROFILE, TASKS, SUBSCRIPTION, FEATURE_FLAGS];

const tabs = [
    {
        route: PROFILE,
        label: "Profile",
        key: PROFILE,
    },
    {
        route: TASKS,
        label: "Tasks",
        key: TASKS,
    },
    {
        route: SUBSCRIPTION,
        label: "Subscription",
        key: SUBSCRIPTION,
    },
    {
        route: FEATURE_FLAGS,
        label: "Feature flags",
        key: FEATURE_FLAGS,
    },
];

export const BrandWrapper = () => {
    const { userId, tabId } = useParams();
    const navigate = useNavigate();

    const [brand, setBrand] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchBrand();
    }, []);

    const fetchBrand = async () => {
        setLoading(true);
        getBrand(userId)
            .then((data) => {
                if (data.success) {
                    setBrand(data.result);
                } else {
                    message.warn("The client data was not found.");
                    navigate("/brands");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (!tabId || tabList.indexOf(tabId) === -1) {
        return <Navigate to={`/brands/${userId}/${PROFILE}`} replace={true} />;
    }

    const onTabChange = (key) => {
        const selectedTab = tabs.find((t) => t.key === key);
        if (selectedTab) {
            navigate(`/brands/${userId}/${selectedTab.route}`);
        }
    };

    const handleBackClick = () => {
        navigate("/brands");
    };

    const content = {
        [PROFILE]: (
            <DocumentTitle
                title={`${brand?.bizNameOrFullName} Profile | Roster Administration Portal`}>
                <Profile brandUser={brand} fetchBrand={fetchBrand} />
            </DocumentTitle>
        ),
        [TASKS]: (
            <DocumentTitle
                title={`${brand?.bizNameOrFullName} Tasks | Roster Administration Portal`}>
                <Tasks />
            </DocumentTitle>
        ),
        [SUBSCRIPTION]: (
            <DocumentTitle
                title={`${brand?.bizNameOrFullName} Subscription | Roster Administration Portal`}>
                <Subscription />
            </DocumentTitle>
        ),
        [FEATURE_FLAGS]: (
            <DocumentTitle
                title={`${brand?.bizNameOrFullName} Feature Flags | Roster Administration Portal`}>
                <FeatureFlags brandUserId={userId} />
            </DocumentTitle>
        ),
    };

    return (
        <>
            <PageHeader
                onBack={handleBackClick}
                extra={
                    <LoginAsButton
                        icon={<UserOutlined />}
                        size="middle"
                        userId={userId}
                        disabled={!brand?.userId}
                    />
                }
                title={`${brand?.bizNameOrFullName || ""} `}
            />
            <Content style={{ padding: "12px 24px" }}>
                <Tabs
                    activeKey={tabId}
                    tabBarStyle={{ margin: 12 }}
                    onChange={onTabChange}
                    size="middle">
                    {tabs.map((tab) => (
                        <TabPane tab={tab.label} key={tab.key}>
                            <Card className="tab-card" loading={loading}>
                                {brand ? content[tab.key] : null}
                            </Card>
                        </TabPane>
                    ))}
                </Tabs>
            </Content>
        </>
    );
};
