export const USER_STATUS_CLOSED = 2;
export const USER_STATUS_ACTIVE = 31;
export const USER_STATUS_PAUSED = 32;

export const BRAND_TYPE_CUSTOMER = 946;
export const BRAND_TYPE_INTERNAL = 947;
export const BRAND_TYPE_DEMO = 1077;

export const USER_SETTING_SALES_CRM_COMPANY_ID = 889;

export const TASK_PROCESS_ACCOUNT_LIST_TO_LIMIT = 105;
export const TASK_DATA_MINING = 93;
export const TASK_IG_TAGGED_MEDIA_SYNC = 348;
export const TASK_IMPORT_CUSTOMER = 842;

export const TASK_STATUS_READY = 35;
export const TASK_STATUS_RUNNING = 36;
export const TASK_STATUS_NEW = 43;
export const TASK_STATUS_ERROR = 38;
export const TASK_STATUS_COMPLETE = 37;
export const TASK_STATUS_CANCELED = 108;
export const TASK_STATUS_WORKFLOW_QUEUE = 492;

export const PAYMENT_GATEWAY_CHARGEBEE = 646;
export const PAYMENT_GATEWAY_SHOPIFY = 880;

export const FREEMIUM_TIER_ID = 1;

export const LOOKUP_TASK_STATUS = "TASK_STATUS";
export const LOOKUP_TASK = "TASK";
export const LOOKUP_SUBSCRIPTION_STATUS = "SUBSCRIPTION_STATUS";
export const LOOKUP_BILL_FREQUENCY = "BILL_FREQUENCY";
export const LOOKUP_SUBSCRIPTION_ITEM = "SUBSCRIPTION_ITEM";
