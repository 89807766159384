import { Switch, Tooltip, message } from "antd";
import axios from "axios";
import { FEATURE_FLAGS_QUERY_KEY } from "common/query-keys";
import { useGetAdminSession } from "hooks/useGetUserSession";
import { useRequestProcessor } from "hooks/useRequestProcessor";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FEATURE_FLAG_PERMISSION_WHITELIST } from "./FeatureFlags";

const FeatureFlagToggle = ({ status, id, defaultStatus }) => {
    const { userId } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [checked, setIsChecked] = useState(status);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const userSession = useGetAdminSession();

    const { useMutate } = useRequestProcessor();

    const { mutateAsync } = useMutate(
        [FEATURE_FLAGS_QUERY_KEY, userId],
        (data) => axios.post("admin/user/flag/update", data),
        {
            enabled: !!userId,
        },
    );

    const handleToggleStatus = (event) => {
        setIsLoading(true);
        setIsChecked(event);

        mutateAsync({
            brandUserId: userId,
            featureFlagId: id,
            status: event,
        })
            .then((res) => {
                if (res.data.success) {
                    message.success("Feature flag status updated successfully");
                }
            })
            .catch(() => {
                message.error("Failed to update feature flag status");
                setIsChecked(!event);
            })
            .finally(() => setIsLoading(false));
    };

    const handleToggleTooltip = (isOpen) => {
        if ((isOpen && defaultStatus) || !isOpen) {
            setIsTooltipOpen(isOpen);
        }
    };

    useEffect(() => {
        setIsChecked(status);
    }, [status]);

    const canEdit =
        FEATURE_FLAG_PERMISSION_WHITELIST?.length > 0
            ? FEATURE_FLAG_PERMISSION_WHITELIST.includes(
                  userSession?.sessionUserId,
              )
            : true;

    return (
        <Tooltip
            open={isTooltipOpen}
            onOpenChange={handleToggleTooltip}
            title="This feature flag is enabled globally. It cannot be changed.">
            <Switch
                onChange={handleToggleStatus}
                checked={checked}
                loading={isLoading}
                disabled={defaultStatus || !canEdit}
            />
        </Tooltip>
    );
};

export default FeatureFlagToggle;
