import useToken from "antd/lib/theme/useToken";

const defaultComponents = {
    components: {
        Button: {
            colorPrimary: "#1F1F1F",
            colorPrimaryHover: "#434343",
            colorPrimaryActive: "#262626",
        },
        Layout: {
            bodyBg: "white",
        },
        Menu: {
            horizontalItemSelectedColor: "#2c2c2c",
            darkItemSelectedBg: "#2c2c2c",
        },
    },
};

export const defaultTheme = {
    token: {
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        lineHeight: 1.5,

        // Color Seed Tokens
        colorTextBase: "#000",
        colorTextLightSolid: "#ffffff",
        colorBgBase: "#ffffff",
        colorPrimary: "#1677ff",
        colorError: "#ff4d4f",
        colorWarning: "#faad14",
        colorSuccess: "#52c41a",
        colorInfo: "#1677ff",

        colorPrimaryTextHover: "#003eb3",
        colorPrimaryText: "#002c8c",
        colorPrimaryTextActive: "#001d66",
        colorSuccessHover: "#73d13d",
        colorSuccessTextHover: "#237804",
        colorSuccessText: "#135200",
        colorSuccessTextActive: "#092b00",
        colorWarningHover: "#ffc53d",
        colorWarningTextHover: "#ad6800",
        colorWarningText: "#874d00",
        colorWarningTextActive: "#613400",
        colorErrorBgHover: "#ffccc7",
        colorErrorBorder: "#ffa39e",
        colorErrorBorderHover: "#ff7875",
        colorErrorActive: "#cf1322",
        colorErrorTextHover: "#a8071a",
        colorErrorText: "#820014",
        colorErrorTextActive: "#5c0011",
        colorInfoHover: "#4096ff",
        colorInfoTextHover: "#003eb3",
        colorInfoText: "#002c8c",
        colorInfoTextActive: "#001d66",
        colorBorder: "rgba(0, 0, 0, 0.15)",
        colorBorderSecondary: "rgba(0, 0, 0, 0.06)",
        colorSplit: "rgba(0, 0, 0, 0.06)",
    },
    ...defaultComponents,
};

export const useThemeToken = () => {
    return useToken();
};
