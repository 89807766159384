import { columnSorterFunc } from "../../brands/brand_columns";

export const ID = {
    dataIndex: "id",
    title: "Id",
};
export const DESCRIPTION = {
    dataIndex: "type",
    title: "Description",
};
export const TYPE = {
    dataIndex: "linkedType",
    title: "Type",
};
export const STATUS = {
    dataIndex: "status",
    title: "Status",
};
export const NEXT_RUN_DATE = {
    dataIndex: "nextRunDate",
    title: "Next Run",
};
export const END_DATE = {
    dataIndex: "endDate",
    title: "End Date",
};

export const columnDef_id = {
    title: ID.title,
    dataIndex: ID.dataIndex,
    key: ID.dataIndex,
    width: "90px",
    sorter: (a, b) => columnSorterFunc(a, b, ID.dataIndex),
};
export const columnDef_description = {
    title: DESCRIPTION.title,
    dataIndex: DESCRIPTION.dataIndex,
    key: DESCRIPTION.dataIndex,
    sorter: (a, b) => columnSorterFunc(a?.type, b?.type, "descr"),
    render: (data) => data?.descr,
};
export const columnDef_type = {
    title: TYPE.title,
    dataIndex: TYPE.dataIndex,
    key: TYPE.dataIndex,
    width: "120px",
    sorter: (a, b) => columnSorterFunc(a?.linkedType, b?.linkedType, "descr"),
    render: (data) => data?.descr,
};
export const columnDef_status = {
    title: STATUS.title,
    dataIndex: STATUS.dataIndex,
    key: STATUS.dataIndex,
    width: "120px",
    sorter: (a, b) => columnSorterFunc(a?.status, b?.status, "descr"),
    render: (data) => data?.descr,
};
export const columnDef_nextRunDate = {
    title: NEXT_RUN_DATE.title,
    dataIndex: NEXT_RUN_DATE.dataIndex,
    key: NEXT_RUN_DATE.dataIndex,
    sorter: (a, b) => columnSorterFunc(a, b, NEXT_RUN_DATE.dataIndex),
    render: (text) => (text ? new Date(text).toLocaleDateString() : null),
    width: "100px",
};
export const columnDef_endDate = {
    title: END_DATE.title,
    dataIndex: END_DATE.dataIndex,
    key: END_DATE.dataIndex,
    sorter: (a, b) => columnSorterFunc(a, b, END_DATE.dataIndex),
    render: (text) => (text ? new Date(text).toLocaleDateString() : null),
    width: "100px",
};

export const tasksColumns = [
    columnDef_id,
    columnDef_description,
    columnDef_type,
    columnDef_status,
    columnDef_nextRunDate,
    columnDef_endDate,
];
