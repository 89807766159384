import { Divider, Layout, Menu, Typography } from "antd";
import { signOut } from "components/login/login.api";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "images/logo_.svg";
import { useCookies } from "react-cookie";
import { defaultCookieOptions, SESSION_KEY } from "common/constants";

const { Sider } = Layout;
const { Text } = Typography;

const menuItems = [
    { key: "brands", route: "/brands", label: "Brand Lookup" },
    { key: "new", route: "/new", label: "Add New Brand" },
];

const PageLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [cookies, setCookies] = useCookies([SESSION_KEY]);

    const openBrandPortal = () => {
        const adminSession = cookies.adminSession;
        setCookies(SESSION_KEY, adminSession, defaultCookieOptions);

        window.location = process.env.REACT_APP_BP_BASE_URL;
    };

    const logOut = () => {
        signOut(() => navigate("/login"));
    };

    return (
        <Layout hasSider style={{ height: "100%" }}>
            <Sider width={160} style={{ backgroundColor: "#000000" }}>
                <div className="flex-container center vertical content-wrapper">
                    <div
                        className="logo"
                        style={{ padding: 24, paddingBottom: 12 }}>
                        <Logo height={30} />
                    </div>
                    <Text strong style={{ color: "#ffffff" }}>
                        Administration
                    </Text>
                    <Divider style={{ borderColor: "#ffffff" }} />

                    <Menu
                        className="inline-antd-menus"
                        theme="dark"
                        mode="inline"
                        selectedKeys={[
                            menuItems.find((item) =>
                                location.pathname.includes(item.route),
                            )?.key || "brands",
                        ]}
                        items={menuItems.map((item) => ({
                            key: item.key,
                            label: <Link to={item.route}>{item.label}</Link>,
                        }))}
                    />

                    <Menu
                        className="inline-antd-menus"
                        theme="dark"
                        mode="inline"
                        style={{ marginTop: "auto" }}
                        selectedKeys={[]}
                        items={[
                            {
                                key: "brand-portal",
                                label: (
                                    <span onClick={openBrandPortal}>
                                        Brand Portal
                                    </span>
                                ),
                            },
                            { type: "divider" },
                            {
                                key: "log-out",
                                label: <span onClick={logOut}>Log Out</span>,
                            },
                        ]}
                    />
                </div>
            </Sider>
            <Layout>
                <Outlet />
            </Layout>
        </Layout>
    );
};

export default PageLayout;
