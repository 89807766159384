import { ADMIN_APP_SESSION_KEY, SESSION_KEY } from "common/constants";
import { Cookies } from "react-cookie";

export const useGetUserSession = () => {
    const cookies = new Cookies();
    return cookies.get(SESSION_KEY);
};

export const useGetAdminSession = () => {
    const cookies = new Cookies();
    return cookies.get(ADMIN_APP_SESSION_KEY);
};
