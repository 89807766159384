import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Table, Typography } from "antd";
import { columnDef_fixedRightAction, SORT_DIRECTIONS } from "common/constants";
import { partial } from "lodash";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TaskModal } from "./TaskModal";
import { getUserTasks } from "./tasks.api";
import { tasksColumns } from "./task_columns";

const { Title } = Typography;

export const Tasks = () => {
    const { userId } = useParams();

    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedTask, setTask] = useState(null);

    useEffect(() => {
        fetchUserTasks();
    }, []);

    const fetchUserTasks = () => {
        setLoading(true);

        getUserTasks(userId)
            .then((data) => {
                setTasks(data.result);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setTask(null);
    };

    const handleOpenModal = (task) => {
        setIsOpen(true);
        setTask(task);
    };

    //action button
    const columnDef_action = {
        ...columnDef_fixedRightAction,
        render: (_, record) => (
            <Button
                type="link"
                shape="circle"
                size="small"
                icon={<EditOutlined />}
                onClick={partial(handleOpenModal, record)}
            />
        ),
    };

    return (
        <>
            <div className="flex-container center">
                <Title level={4} style={{ marginRight: "auto" }}>
                    Pacman Tasks{" "}
                </Title>

                <Button
                    type="link"
                    icon={<PlusCircleOutlined />}
                    onClick={partial(handleOpenModal, null)}>
                    Add New Task
                </Button>
            </div>
            <Table
                bordered
                columns={[...tasksColumns, columnDef_action]}
                dataSource={tasks}
                loading={loading}
                pagination={{
                    position: ["bottomCenter"],
                    hideOnSinglePage: true,
                    pageSizeOptions: [20, 50, 100],
                    defaultPageSize: 20,
                    // size: "default",
                    showTotal: (total) => `Total ${total} items`,
                }}
                rowKey="id"
                scroll={{
                    scrollToFirstRowOnChange: true,
                    y: "calc(100vh - 310px)",
                    x: 800,
                }}
                size="small"
                sortDirections={SORT_DIRECTIONS}
                style={{ width: "100%", height: "100%" }}
            />
            <TaskModal
                isOpen={isOpen}
                onCancel={handleCloseModal}
                onSuccess={fetchUserTasks}
                task={selectedTask}
            />
        </>
    );
};
