import { PageHeader } from "@ant-design/pro-layout";
import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Row,
    Select,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import {
    requiredFormRule,
    requiredFormRuleNumeric,
    brandTypes,
} from "common/constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createNewUser } from "./brands/brands.api";
import { SaveOutlined } from "@ant-design/icons";

export const NewBrandForm = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const eCommercePlatforms = [
        { label: "Shopify", value: "Shopify" },
        { label: "Big Commerce", value: "Big Commerce" },
        { label: "Magento", value: "Magento" },
        { label: "Salesforce", value: "Salesforce" },
        { label: "Woo Commerce", value: "Woo Commerce" },
        { label: "Other", value: "Other" },
    ];

    const createUser = (values) => {
        setIsLoading(true);
        const {
            firstName,
            email,
            lastName,
            businessName,
            brandDomain,
            brandTypeId,
            eCommPlatform,
        } = values;

        const request = {
            user: { firstName, lastName, email, businessName },
            brandDomain,
            brandTypeId,
            eCommPlatform,
            // createNewDatabase: false,
        };

        // Team member field validations
        if (email && (!firstName || !lastName)) {
            message.warning("Provide a name for the team member.");
            setIsLoading(false);
        } else {
            createNewUser(request)
                .then((data) => {
                    if (data?.success) {
                        const newUserId = data.result;
                        message.success(data.message);
                        navigate(`/brands/${newUserId}/profile`);
                    } else {
                        message.warning(data.message);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <Content>
            <Form
                autoComplete="off"
                disabled={isLoading}
                //disabled={true} // No longer supporting creating a brand via the admin portal
                layout="vertical"
                onFinish={createUser}
                validateTrigger={false}>
                <PageHeader
                    title="Add New Brand"
                    extra={
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button
                                type="primary"
                                icon={<SaveOutlined />}
                                htmlType="submit">
                                Create Brand
                            </Button>
                        </Form.Item>
                    }
                />
                <Alert
                    className="form-alert"
                    message="This form is no longer fully supported for creating a new brand. If it needs to be used, work with the engineering team to get a Chargebee subscription associated with the new brand before they attempt to start onboarding. It's best to allow the engineering team to create the new brand account so we can immediately associate the subscription right away."
                    // message="You do not have access to create a brand account here. It must be done through the following options: Chargebee Quote, Self Onboarding, Shopify Install."
                    type="warning"
                    showIcon
                />

                <Row gutter={12} wrap={false} style={{ margin: 12 }}>
                    <Col span={12} flex="auto">
                        <Card style={{ margin: 12 }} title="Brand Information">
                            <Form.Item
                                label="Business Name"
                                name="businessName"
                                extra="The business name that will be displayed on Roster."
                                required
                                rules={[requiredFormRule]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Brand's Ambassador Portal Domain"
                                name="brandDomain"
                                extra="Sets the branded link for the ambassador portal. (e.g. roster.link/{domain})"
                                required
                                rules={[
                                    { requiredRule: requiredFormRule },
                                    {
                                        validator(_, value) {
                                            if (
                                                value?.match(/^[a-zA-Z0-9-]+$/)
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'Brand Domain can only contain characters or numbers and the "-" character.',
                                                ),
                                            );
                                        },
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Account Type"
                                name="brandTypeId"
                                extra="Is this new account a brand customer or an internal test account?"
                                required
                                rules={[requiredFormRuleNumeric]}>
                                <Select
                                    placeholder="Select"
                                    options={brandTypes}
                                />
                            </Form.Item>
                            <Form.Item
                                label="eCommerce Platform"
                                name="eCommPlatform"
                                extra="The ecommerce platform the brand is using."
                                required
                                rules={[requiredFormRule]}>
                                <Select
                                    placeholder="What do you use?"
                                    options={eCommercePlatforms}
                                />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={12} flex="auto">
                        <Card
                            style={{ margin: 12 }}
                            title="Initial Admin Team Member (optional)">
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                extra="The first name of the initial admin team member.">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                extra="The last name of the initial admin team member.">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name="email"
                                extra="An email will be sent to this address to setup a password and begin onboarding."
                                rules={[
                                    {
                                        type: "email",
                                        message: "Provide a valid email",
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Content>
    );
};
