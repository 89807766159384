import { addMinutes, isDate, parseISO } from "date-fns";

export const fromUTC = (date) => {
    if (!date) {
        return;
    }

    const now = new Date(date);
    const offset = now.getTimezoneOffset();
    return addMinutes(isDate(date) ? date : parseISO(date), -offset);
};

export const toUTC = (date) => {
    if (!date) {
        return;
    }
    const now = new Date(date);
    const offset = now.getTimezoneOffset();
    return addMinutes(isDate(date) ? date : parseISO(date), offset);
};
